<template>
  <f7-page login-screen>
    <navbar></navbar>

    <f7-block class="text-center">
      <img style="height: 150px;" src="/static/best-coach.png" alt />
    </f7-block>
    <f7-block class="text-center">
      <h1 class="mb-0">{{ 'best_coach.header_primary_1' | translate }}</h1>
      <h1 class="mt-0 mb-0">{{ 'best_coach.header_primary_2' | translate }}</h1>
    </f7-block>
    <f7-block class="text-center">
      <p>{{ 'best_coach.section_primary_1' | translate }}</p>
      <p>{{ 'best_coach.section_primary_2' | translate }}</p>
    </f7-block>
    <f7-block class="text-center align-items-center">
      <img style="max-height: 50px" src="/static/company/asystent_trenera.png" alt="">
      <img style="max-height: 50px" src="/static/company/pozytywny_coaching.png" alt="">
      <img style="max-height: 50px" src="/static/company/folder_srodkow_treningowych.png" alt="">
      <img style="max-height: 50px" src="/static/company/emsport.png" alt="">
      <img style="max-height: 50px" src="/static/company/better_way.png" alt="">
      <img style="max-height: 50px" src="/static/company/only_futbol.png" alt="">
      <img style="max-height: 50px" src="/static/company/adler_coach.png" alt="">
      <img style="max-height: 50px" src="/static/company/juniorski_mecz.png" alt="">
      <img style="max-height: 50px" src="/static/company/strefa_futbolu.png" alt="">
      <img style="max-height: 50px" src="/static/company/falcons.png" alt="">
      <img style="max-height: 50px" src="/static/company/pilkarski_trening.png" alt="">
      <img style="max-height: 50px" src="/static/company/pilka_nozna_dla_dzieci.png" alt="">
      <img style="max-height: 50px" src="/static/company/football_mind.png" alt="">
      <img style="max-height: 50px" src="/static/company/football_coaching_lab.png" alt="">
      <img style="max-height: 50px" src="/static/company/football_level_up.png" alt="">
      <img style="max-height: 50px" src="/static/company/psychosportica.png" alt="">
      <img style="max-height: 50px" src="/static/company/keepers_foundation.png" alt="">
      <img style="max-height: 50px" src="/static/company/trening_dzieci_2_0.png" alt="">
      <img style="max-height: 50px" src="/static/company/football_insight.png" alt="">
    </f7-block>
    <f7-block class="text-center">
      <h2><span style="text-decoration: line-through; color: red" class="mr-1">{{ normalPrice }} {{getLang == 'pl' ? 'PLN' : 'USD'}}</span>{{ price }} {{getLang == 'pl' ? 'PLN' : 'USD'}}</h2>
      <f7-button raised small @click="goToStripe()" external target="_blank" class="font-weight-bold external">{{ 'best_coach.buy_now' | translate }}</f7-button>
    </f7-block>
    
    <f7-block class="text-center">
      <h2>{{ 'best_coach.header_about_1' | translate }}</h2>
    </f7-block>
    <f7-block class="text-center">
      <p>{{ 'best_coach.section_about_1' | translate }}</p>
      <p>{{ 'best_coach.section_about_2' | translate }}</p>
      <img height="60px" src="/static/icons/platform.svg" alt />
      <h3 class="text-uppercase mt-0">{{ 'best_coach.section_about_3' | translate }}</h3>
      <p>{{ 'best_coach.section_about_4' | translate }}</p>
      <img height="60px" src="/static/icons/private-material.svg" alt />
      <h3 class="text-uppercase mt-0">{{ 'best_coach.section_about_5' | translate }}</h3>
      <p>{{ 'best_coach.section_about_6' | translate }}</p>
      <img height="60px" src="/static/icons/community.svg" alt />
      <h3 class="text-uppercase mt-0">{{ 'best_coach.section_about_7' | translate }}</h3>
      <p>{{ 'best_coach.section_about_8' | translate }}</p>
    </f7-block>
    <f7-block class="text-center">
      <p>{{ 'best_coach.section_about_9' | translate }}</p>
    </f7-block>
    <f7-block class="text-center">
      <h2><span style="text-decoration: line-through; color: red" class="mr-1">{{normalPrice}} {{getLang == 'pl' ? 'PLN' : 'USD'}}</span>{{ price }} {{getLang == 'pl' ? 'PLN' : 'USD'}}</h2>
      <f7-button raised small @click="goToStripe()" external target="_blank" class="font-weight-bold external">{{ 'best_coach.buy_publications' | translate }}</f7-button>
    </f7-block>

    <f7-block class="text-center" style="background: black; padding: 20px;">
      <p class="text-uppercase" style="color: white;">{{ 'best_coach.quote' | translate }}</p>
    </f7-block>

    <f7-block class="text-center">
      <h2>{{ 'best_coach.header_product_1' | translate }}</h2>
    </f7-block>

    <f7-block class="text-center">
      <img style="height: 150px; border-radius: 10px" :src="`/static/faq/plan_1.png`" alt />
    </f7-block>

    <f7-block class="text-center">
      <p>{{ 'best_coach.section_product_1' | translate }}</p>
      <p>{{ 'best_coach.section_product_2' | translate }}</p>
    </f7-block>

    <f7-block class="text-center">
      <p class="font-weight-bold mb-0">Konspekty Piłka Nożna</p>
      <p class="font-weight-bold mb-0 mt-0">Asystent Trenera</p>
      <p class="font-weight-bold mb-0 mt-0">Pozytywny Coaching</p>
      <p class="font-weight-bold mb-0 mt-0">Folder Środków Treningowych</p>
      <p class="font-weight-bold mb-0 mt-0">Better Way</p>
      <p class="font-weight-bold mb-0 mt-0">Only Futbol</p>
      <p class="font-weight-bold mb-0 mt-0">Adler Coach</p>
      <p class="font-weight-bold mb-0 mt-0">Juniorski Mecz</p>
      <p class="font-weight-bold mb-0 mt-0">Strefa Futbolu</p>
      <p class="font-weight-bold mb-0 mt-0">Polonia Falcons</p>
      <p class="font-weight-bold mb-0 mt-0">Piłkarski Trening</p>
      <p class="font-weight-bold mb-0 mt-0">Piłka Nożna Dla Dzieci</p>
      <p class="font-weight-bold mb-0 mt-0">PsychoSportica</p>
      <p class="font-weight-bold mb-0 mt-0">Emsport</p>
      <p class="font-weight-bold mb-0 mt-0">Football Coaching Lab</p>
      <p class="font-weight-bold mb-0 mt-0">Football Mind</p>
      <p class="font-weight-bold mb-0 mt-0">Football Level Up</p>
      <p class="font-weight-bold mb-0 mt-0">Keepers Foundation</p>
      <p class="font-weight-bold mb-0 mt-0">Trening Dzieci 2.0</p>
      <p class="font-weight-bold mt-0">Football Insight</p>
    </f7-block>

    <f7-block class="text-center">
      <p>{{ 'best_coach.section_product_3' | translate }}</p>
      <p class="text-uppercase font-weight-bold">{{ 'best_coach.section_product_4' | translate }}</p>
      <p>{{ 'best_coach.section_product_5' | translate }}</p>
    </f7-block>

    <f7-block class="text-center">
      <video id="video-player" width="100%" controls loop muted>
        <source src="/static/faq/soccer_sessions_mobile_app_promo_.mp4" type="video/mp4">
      </video>
    </f7-block>

    <f7-block class="text-center">
      <p>{{ 'best_coach.section_product_6' | translate }}</p>
      <p>{{ 'best_coach.section_product_7' | translate }}</p>
    </f7-block>

    <f7-block class="text-center">
      <h2><span style="text-decoration: line-through; color: red" class="mr-1">{{ normalPrice }} {{getLang == 'pl' ? 'PLN' : 'USD'}}</span>{{ price }} {{getLang == 'pl' ? 'PLN' : 'USD'}}</h2>
      <f7-button raised small @click="goToStripe()" external target="_blank" class="font-weight-bold external">{{ 'best_coach.buy_right_now' | translate }}</f7-button>
    </f7-block>

    <f7-block class="text-center">
      <h2>{{ 'best_coach.header_advantage_1' | translate }}</h2>
    </f7-block>

    <f7-block class="text-center">
      <p>{{ 'best_coach.section_advantage_1' | translate }}</p>
      <p>{{ 'best_coach.section_advantage_2' | translate }}</p>
      <p>{{ 'best_coach.section_advantage_3' | translate }}</p>
      <p>{{ 'best_coach.section_advantage_4' | translate }}</p>
      <p>{{ 'best_coach.section_advantage_5' | translate }}</p>
      <p>{{ 'best_coach.section_advantage_6' | translate }}</p>
    </f7-block>

    <f7-block class="text-center">
      <h2>{{ 'best_coach.header_publications' | translate }}</h2>
    </f7-block>

    <f7-block class="d-flex justify-content-center" style="flex-wrap: wrap;">
        <f7-link v-for="book in books" :key="book.id" @click="goToStripe()" :tooltip="book.title" >
          <img width="50px" :src="`${host}${book.cover}`"  alt />
        </f7-link>
    </f7-block>

    <f7-block class="text-center">
      <p>{{ 'best_coach.section_publications' | translate }}</p>
    </f7-block>

    <f7-block class="text-center">
      <h2><span style="text-decoration: line-through; color: red" class="mr-1">{{ normalPrice }} {{getLang == 'pl' ? 'PLN' : 'USD'}}</span>{{ price }} {{getLang == 'pl' ? 'PLN' : 'USD'}}</h2>
      <f7-button raised small @click="goToStripe()" external target="_blank" class="font-weight-bold external">{{ 'best_coach.buy_now' | translate }}</f7-button>
    </f7-block>

    <f7-block class="text-center">
      <h2>{{ 'best_coach.header_faq' | translate }}</h2>
    </f7-block>

    <f7-block class="text-center">
      <p class="font-weight-bold">{{ 'best_coach.section_faq_1' | translate }}</p>
      <p>{{ 'best_coach.section_faq_2' | translate }} <f7-link href="mailto:contact@soccer-sessions.com" external><strong>contact@soccer-sessions.com</strong></f7-link></p>
      <p class="font-weight-bold">{{ 'best_coach.section_faq_3' | translate }}</p>
      <p>{{ 'best_coach.section_faq_4' | translate }}</p>
      <p class="font-weight-bold">{{ 'best_coach.section_faq_5' | translate }}</p>
      <p>{{ 'best_coach.section_faq_6' | translate }}</p>
      <p class="font-weight-bold">{{ 'best_coach.section_faq_7' | translate }}</p>
      <p>{{ 'best_coach.section_faq_8' | translate }}</p>
      <p class="font-weight-bold">{{ 'best_coach.section_faq_9' | translate }}</p>
      <p>{{ 'best_coach.section_faq_10' | translate }}</p>
      <p class="font-weight-bold">{{ 'best_coach.section_faq_11' | translate }}</p>
      <p>{{ 'best_coach.section_faq_12' | translate }}</p>
      <p class="font-weight-bold">{{ 'best_coach.section_faq_13' | translate }}</p>
      <p>{{ 'best_coach.section_faq_14' | translate }}</p>
      <p>{{ 'best_coach.section_faq_15' | translate }} <f7-link href="mailto:contact@soccer-sessions.com" external><strong>contact@soccer-sessions.com</strong></f7-link></p>
      <p>{{ 'best_coach.section_faq_16' | translate }}</p>
    </f7-block>

    <f7-block class="text-center">
      <h2><span style="text-decoration: line-through; color: red" class="mr-1">{{normalPrice}} {{getLang == 'pl' ? 'PLN' : 'USD'}}</span>{{ price }} {{getLang == 'pl' ? 'PLN' : 'USD'}}</h2>
      <f7-button raised small @click="goToStripe()" external target="_blank" class="font-weight-bold external">{{ 'best_coach.buy_right_now_2' | translate }}</f7-button>
    </f7-block>

    <f7-block class="text-center">
      <img style="width: 200px;" src="/static/faq/iframe-app-promo.png" alt />
    </f7-block>

  </f7-page>
</template>
<script>
import CONSTANTS from "@/utils/constants.js"
import Navbar from "@/components/Navbar/Navbar.vue";
import authServices from "@/services/AuthServices.js"

export default {
  components: {
    Navbar,
  },
  data: () => {
    return {
      subscriptionsList: CONSTANTS.STRIPE_REDIRECTS,
      subscriptionPromo: CONSTANTS.STRIPE_PROMO,
      value: '',
      books: [],
    };
  },
  computed: {
    price() {
      return this.value == 'rGn3R' ? 39 : this.getLang == 'pl' ? 59 : 14.99
    },
    normalPrice() {
      return this.getLang == 'pl' ? 79 : 29.99
    }
  },
  mounted() {
    if (window.location.host == 'app.soccer-sessions.com') {
      // Pixel facebook
      window.fbq('track', 'InitiateCheckout');

      // User.com event
      window.userengage('event.add_to_cart');
    };
    this.fetchDataBooksSmallList()
    if (this.$f7route.query.value !== undefined) {
        this.value = this.$f7route.query.value
    }
    if (this.$f7route.query.lang !== undefined) {
        const lang = this.$f7route.query.lang
        if (this.getLang !== lang) {
          this.changeLang()
        }
    }
  },
  methods: {
    goToStripe() {
      window.open(this.value == 'rGn3R' ? this.subscriptionPromo.link : this.getLang == 'pl' ? this.subscriptionsList[0][0].link : this.subscriptionsList[1][0].link, '_blank');
    },
    async fetchDataBooksSmallList() {
      try {
        const responseDataBooksSmall = await authServices.booksSmallList();
        this.books = responseDataBooksSmall.data.filter(book => book.demo = true)
      } catch (error) {
        this.$f7.toast
          .create({
            text: "Something went wrong. Please try again or contact your administrator.",
                        position: "bottom",
            
            closeTimeout: 3000
          })
          .open();
        console.log(error);
      }
    }
  },
};
</script>

<style lang="scss">
  @import "@/assets/scss/pages/payments.scss";
</style>