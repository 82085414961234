import Api from './index'
import store from '@/store'

export default {
  async token(username, password) {
    const data = {
      username,
      password
    }
    return Api().post('token/', data)
  },
  async register(email) {
    const data = {
      email
    }
    return Api().post('register/', data)
  },
  async tokenVerify(token) {
    const data = {
      token
    }
    return Api().post('token/verify/', data, {
        headers: {
            'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
        }
    })
  },
  async tokenRefresh(token) {
    return Api().post('token/refresh/', token)
  },
  async requestResetPassword(email) {
    const data = {
      email
    }
    return Api().post('reset-password/', data)
  },
  async validateToken(token, email) {
    const data = {
      token,
      email
    }
    return Api().post('reset-password/validate_token/', data)
  },
  async confirmResetPassword(token, password) {
    const data = {
      token,
      password
    }
    return Api().post('reset-password/confirm/', data)
  },
  async changePassword(new_password, old_password) {
    const data = {
      new_password,
      old_password
    }
    return Api().put('change-password/', data, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
  })
  },
  async cancelSubscription() {
    return Api().post('user/cancel-subscription/', {}, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async changeRole(id) {
    const data = {
      role_id: id
    }
    return Api().put('role/', data, {
        headers: {
            'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
        }
    })
  },
  async user() {
    return Api().get('user/', {
        headers: {
            'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
        }
    })
  },
  async userBooks() {
    return Api().get('user/books/', {
        headers: {
            'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
        }
    })
  },
  async userBooksSmall() {
    return Api().get('user/books-small/', {
        headers: {
            'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
        }
    })
  },
  async updateUser(data) {
    return Api().put('user/update/', data, {
        headers: {
            'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
        }
    })
  },
  async userFavoritesDone() {
    return Api().get('user/favorites-done/', {
        headers: {
            'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
        }
    })
  },
  async booksSmallList() {
    return Api().get('books/small/')
  },
  async books(category, partner) {
    return Api().get(`books/?category=${category}&partners=${partner}`)
  },
  async book(id) {
    return Api().get(`books/${id}/`, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
    })
  },
  async bookDemo(id) {
    return Api().get(`books/demo/${id}/`)
  },
  async updateFavorites(favorites) {
    return Api().put('user/update/favorites/', favorites, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
  })
  },
  async updateDone(done) {
    return Api().put('user/update/done/', done, {
      headers: {
          'Authorization': `Bearer ${store.getters['appkonspektypilkanozna/myToken']}`,
      }
  })
  },
}